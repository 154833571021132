<template>
  <div class="my-2">
    <a v-if="byAccount"
       :href="'users/'+ user?.id"
       target="_blank"
       class="text-no-wrap text-decoration-none font-weight-medium grey--text text--darken-3">
      <v-avatar size="38">
        <v-img
            v-if="user?.photo"
            :src="fileUrl + user?.photo"
        />
        <v-img
            v-else
            :src="require('@/assets/avatar.png')"
        >
        </v-img>
      </v-avatar>

      <v-icon class="icon-check"
              small
              v-if="user.user?.verification_status === 'verified'"
              color="info">mdi-check-decagram
      </v-icon>

      <v-icon class="icon-check"
              v-else
              small
              color="orange">mdi-alert-decagram
      </v-icon>

      <span class="name">
         {{ user.user?.name }}
       </span>
    </a>

    <a v-else
       :href="'users/'+ user?.account?.id"
       target="_blank"
       class="text-no-wrap text-decoration-none font-weight-medium grey--text text--darken-3">
      <v-avatar size="38">
        <v-img
            v-if="user?.account?.photo"
            :src="fileUrl + user?.account?.photo"
        />
        <v-img
            v-else
            :src="require('@/assets/avatar.png')"
        >
        </v-img>
      </v-avatar>

      <v-icon class="icon-check"
              small
              v-if="user.verification_status === 'verified'"
              color="info">mdi-check-decagram
      </v-icon>

      <v-icon class="icon-check"
              v-else
              small
              color="orange">mdi-alert-decagram
      </v-icon>

      <span class="name">
         {{ user.name }}
       </span>
    </a>

  </div>
</template>

<script>
export default {
  props: ['user', 'byAccount'],
  data() {
    return {
      fileUrl: process.env.VUE_APP_FILE_URL,
    }
  }
}
</script>

<style scoped>
.icon-check {
  top: -12px;
  left: -14px;
  background-color: #FFFFFF;
  border-radius: 50%;
  width: 19px;
  height: 19px
}

.name {
  margin-left: -15px
}
</style>